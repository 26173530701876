@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-gradient {
  background: linear-gradient(
    135deg,
    rgba(0, 97, 215, 1) 0%,
    rgba(0, 200, 255, 1) 100%
  );
}